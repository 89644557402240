<template>
  <hr class="featurette-divider">
  <h1 class="center-heading">Ein kleiner Einblick in unsere Projekte</h1>
<Lightbox></Lightbox>
</template>


<script>
import Lightbox from './ProjectLightbox.vue';

export default {
  components: {
    Lightbox
  },
  methods: {
  }
}
</script>


<style>

* {
  box-sizing: border-box;
}

.row {
  display: -ms-flexbox; /* IE 10 */
  display: flex;
  -ms-flex-wrap: wrap; /* IE 10 */
  flex-wrap: wrap;
  padding: 0 4px;
}

/* Create two equal columns that sits next to each other */
.column {
  -ms-flex: 25%; /* IE 10 */
  flex: 25%;
  padding: 0 4px;
}

.column img {
  margin-top: 8px;
  vertical-align: middle;
}
/* Responsive layout - makes a two column-layout instead of four columns */
@media screen and (max-width:800px) {
 .column {
   flex:50%;
 }
}

/* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
@media screen and (max-width:600px) {
 .column {
   flex:100%;
 }
}
</style>
