<template>
  <div class="row featurette">
    <div class="col-md-7">
      <h2 class="featurette-heading">
        Kompetent. Fair. Verlässlich. <span class="text-muted"></span>
      </h2>
      <p class="lead">
        Die Roman Siegl GmbH mit Firmensitz in Kirchdorf / Krems wurde 2012
        gegründet und beschäftigt aktuell rund 20 Mitarbeiter. Namhafte
        Bauunternehmen sowie Baumeister und Architekten konnten als Kunden
        gewonnen werden, die bei ihren Projekten seit Jahren auf unsere
        Erfahrung vertrauen. Flexibilität und die Qualität bei der Ausführung
        der Arbeiten machen uns zu einem starken Partner.
      </p>
    </div>
    <div class="col-md-5">
      <img src="../assets/img/insta-image-5.jpg" class="img-fluid" alt="Bild von Roman Siegl." width="500" height="500" />
      <p>Geschäftsführer Roman Siegl, MBA.</p>
    </div>
  </div>
  <hr class="featurette-divider" />
  <div class="row featurette">
    <div class="col-md-7 order-md-2">
      <h2 class="featurette-heading">
        Objektsicherheitsprüfung.
        <span class="text-muted">Beratung vom Experten.</span>
      </h2>
      <p class="lead">
        Eigentümer von Wohngebäuden tragen eine besondere Verantwortung für die Sicherheit und Gesundheit in ihren
        Gebäuden. Sie haben dafür Sorge zu tragen, dass von ihrem Eigentum keine Gefahr für die Sicherheit von Personen
        oder deren Eigentum ausgeht. Die ÖNORM B1300-Überprüfung zeigt Mängel auf, die für den Nutzer oft nicht erkennbar
        sind und bei Nichtbehebung zu erheblichen Folgen führen kann.
      </p>
    </div>
    <div class="col-md-5 order-md-1">
      <!--
        <svg class="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto" width="500" height="500" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid slice" focusable="false" role="img" aria-label="Placeholder: 500x500"><title>Placeholder</title><rect width="100%" height="100%" fill="#eee"/><text x="50%" y="50%" fill="#aaa" dy=".3em">500x500</text></svg>
        -->
      <img src="..\assets\img\IMG_5110.jpg" class="img-fluid" alt="Bild des Zertifikats der Bauakademie." width="500"
        height="500" />
    </div>
  </div>

  <hr class="featurette-divider" />

  <div class="row featurette">
    <div class="col-md-7">
      <h2 class="featurette-heading">
        WDVS – Pflege & Wartung<span class="text-muted"></span>
      </h2>
      <p class="lead">
        Die Wartung der Fassadenfläche hat in regelmäßigen Abständen in
        Abhängigkeit von Größe, Architektur und Lage zu erfolgen. In
        wiederkehrenden Abständen ist die Fassade von einem Fachmann zu
        begutachten.
      </p>
    </div>
    <div class="col-md-5">
      <img src="..\assets\img\car1.jpg" class="img-fluid" alt="Bild des Zertifikats der Bauakademie." width="500"
        height="500" />
    </div>
  </div>
</template>
