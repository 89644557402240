import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView'
import AboutView from '../views/AboutView'
import ContactView from '../views/ContactView'
import FacadesView from '../views/FacadesView'
import GalleryView from '../views/GalleryView'
import PlasteringInsideView from '../views/PlasteringInsideView'
import PlasteringOutsideView from '../views/PlasteringOutsideView'
import ScreedView from '../views/ScreedView'
import PrivacyView from '../views/PrivacyView'
import ImpressumView from '../views/ImpressumView'
import WdvsView from '../views/WdvsView'
import UnderConstruction from '../views/UnderConstruction'

const routes = [
  {
    path: '',
    name: 'Home',
    component: HomeView
  },
  {
    path: '/Home',
    name: 'Home',
    component: HomeView
  },
  {
    path: '/contact',
    name: 'Contact',
    component: ContactView
  },
  {
    path: '/Facades',
    name: 'Facades',
    component: UnderConstruction
  },
  {
    path: '/Fassaden',
    name: 'Facades',
    component: UnderConstruction
  },
  {
    path: '/Estrich',
    name: 'Screed',
    component: UnderConstruction
  },
  {
    path: '/innenputz',
    name: 'InsidePlastering',
    component: UnderConstruction
  },
  {
    path: '/außenputz',
    name: 'OutsidePlastering',
    component: UnderConstruction
  },
  {
    path: '/datenschutz',
    name: 'Privacy',
    component: PrivacyView
  },
  {
    path: '/Impressum',
    name: 'Impressum',
    component: ImpressumView
  },
  {
    path: '/Wdvs',
    name: 'Wärmedämmung',
    component: UnderConstruction
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'Home',
    component: HomeView
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior () {
    return { x: 0, y: 0 }
  }
})

export default router
