<template>
    <div class="LightBoxWrapper">
        <div class="row">
            <div class="column">
                <img loading="lazy" src="../assets/img/projekt1.jpg" style="height: 100%; width:100%; object-fit: fill; padding: 1rem;" @click="openModal(); currentSlide(1)"
                    class="hover-shadow cursor">
            </div>
            <div class="column">
                <img loading="lazy" src="../assets/img/house-nice-5.jpg" style="height: 100%; width:100%; object-fit: contain; padding: 1rem;" @click="openModal(); currentSlide(2)"
                    class="hover-shadow cursor">
            </div>
            <div class="column">
                <img loading="lazy" src="../assets/img/project-opener-1.jpg" style="height: 100%; width:100%; object-fit: contain; padding: 1rem;" @click="openModal(); currentSlide(3)"
                    class="hover-shadow cursor">
            </div>
            <div class="column">
                <img loading="lazy" src="../assets/img/project-opener.jpg" style="height: 100%; width:100%; object-fit: contain; padding: 1rem;" @click="openModal(); currentSlide(4)"
                    class="hover-shadow cursor">
            </div>
            <div class="column">
                <img loading="lazy" src="../assets/img/house1.jpg" style="height: 100%; width:100%; object-fit: contain; padding: 1rem;" @click="openModal(); currentSlide(5)"
                    class="hover-shadow cursor">
            </div>
            <div class="column">
                <img loading="lazy" src="../assets/img/house2.jpg" style="height: 100%; width:100%; object-fit: contain; padding: 1rem;" @click="openModal(); currentSlide(6)"
                    class="hover-shadow cursor">
            </div>
            <div class="column">
                <img loading="lazy" src="../assets/img/house3.jpg" style="height: 100%; width:100%; object-fit: contain; padding: 1rem;" @click="openModal(); currentSlide(7)"
                    class="hover-shadow cursor">
            </div>
            <div class="column">
                <img loading="lazy" src="../assets/img/insta-image-1.jpg" style="height: 100%; width:100%; object-fit: contain; padding: 1rem;" @click="openModal(); currentSlide(8)"
                    class="hover-shadow cursor">
            </div>
        </div>

        <div id="myModal" class="modal" :style="{ display: modalVisible ? 'block' : 'none' }">
            <span class="close cursor" @click="closeModal()">&times;</span>
            <div class="modal-content">

                <div class="mySlides">
                    <div class="numbertext">1 / 8</div>
                    <img loading="lazy" src="../assets/img/projekt1.jpg" style="width:100%">
                </div>

                <div class="mySlides">
                    <div class="numbertext">2 / 8</div>
                    <img loading="lazy" src="../assets/img/house-nice-5.jpg" style="width:100%">
                </div>

                <div class="mySlides">
                    <div class="numbertext">3 / 8</div>
                    <img loading="lazy" src="../assets/img/project-opener-1.jpg" style="width:100%">
                </div>

                <div class="mySlides">
                    <div class="numbertext">4 / 8</div>
                    <img loading="lazy" src="../assets/img/project-opener.jpg"
                        style="width:100%">
                </div>

                <div class="mySlides">
                    <div class="numbertext"> 5 / 8</div>
                    <img loading="lazy" src="../assets/img/house1.jpg" style="width:100%">
                </div>

                <div class="mySlides">
                    <div class="numbertext"> 6 / 8</div>
                    <img loading="lazy" src="../assets/img/house2.jpg" style="width:100%">
                </div>

                <div class="mySlides">
                    <div class="numbertext"> 7 / 8</div>
                    <img loading="lazy" src="../assets/img/house3.jpg"  style="width:100%">
                </div>

                <div class="mySlides">
                    <div class="numbertext"> 8 / 8</div>
                    <img loading="lazy" src="../assets/img/insta-image-1.jpg" style="width:100%">
                </div>

                <a class="prev" @click="plusSlides(-1)">&#10094;</a>
                <a class="next" @click="plusSlides(1)">&#10095;</a>

                <div class="caption-container">
                    <p id="caption"></p>
                </div>


                <div class="column">
                    <img loading="lazy" class="demo cursor" src="../assets/img/projekt1.jpg" style="width:100%" @click="currentSlide(1)"
                        alt="Bauernkammer Kirchdorf">
                </div>
                <div class="column">
                    <img loading="lazy" class="demo cursor" src="../assets/img/house-nice-5.jpg" style="width:100%"
                        @click="currentSlide(2)" alt="Privatgebäude">
                </div>
                <div class="column">
                    <img loading="lazy" class="demo cursor" src="../assets/img/project-opener-1.jpg" style="width:100%"
                        @click="currentSlide(3)" alt="Gebäude">
                </div>
                <div class="column">
                    <img loading="lazy" class="demo cursor" src="../assets/img/project-opener.jpg" style="width:100%"
                        @click="currentSlide(4)" alt="Gebäude">
                </div>
                <div class="column">
                    <img loading="lazy" src="../assets/img/house1.jpg" style="width:100%" @click="currentSlide(5)"
                        class="demo cursor">
                </div>
                <div class="column">
                    <img loading="lazy" src="../assets/img/house2.jpg" style="width:100%" @click="currentSlide(6)"
                        class="demo cursor">
                </div>
                <div class="column">
                    <img loading="lazy" src="../assets/img/house3.jpg" style="width:100%" @click="currentSlide(7)"
                        class="demo cursor">
                </div>
                <div class="column">
                    <img loading="lazy" src="../assets/img/insta-image-1.jpg" style="width:100%" @click="currentSlide(8)"
                        class="demo cursor">
                </div>
            </div>
        </div>
    </div>
</template>


<script>
export default {
    data() {
        return {
            modalVisible: false,
            slideIndex: 1,
        };
    },
    methods: {
        openModal() {
            this.modalVisible = true;
            this.showSlides(this.slideIndex);
        },
        closeModal() {
            this.modalVisible = false;
        },
        plusSlides(n) {
            this.showSlides(this.slideIndex += n);
        },
        currentSlide(n) {
            this.showSlides(this.slideIndex = n);
        },
        showSlides(n) {
            var i;
            var slides = document.getElementsByClassName("mySlides");
            var dots = document.getElementsByClassName("demo");
            var captionText = document.getElementById("caption");
            if (n > slides.length) { this.slideIndex = 1 }
            if (n < 1) { this.slideIndex = slides.length }
            for (i = 0; i < slides.length; i++) {
                slides[i].style.display = "none";
            }
            for (i = 0; i < dots.length; i++) {
                dots[i].className = dots[i].className.replace(" active", "");
            }
            slides[this.slideIndex - 1].style.display = "block";
            dots[this.slideIndex - 1].className += " active";
            captionText.innerHTML = dots[this.slideIndex - 1].alt;
        },
        onKeyDown(e) {
            if (this.modalVisible) {
                switch (e.key) {
                    case 'ArrowRight':
                        this.plusSlides(1);
                        break;
                    case 'ArrowLeft':
                        this.plusSlides(-1);
                        break;
                    case 'ArrowDown':
                    case 'ArrowUp':
                    case ' ':
                        e.preventDefault();
                        break;
                    case 'Escape':
                        this.closeModal();
                        break;
                }
            }
        }
    },
    mounted() {
        window.addEventListener('keydown', this.onKeyDown)
    },
    unmounted() {
        window.removeEventListener('keydown', this.onKeyDown)
    }
};
</script>

<style>
/*above is all new stuff */
.LightBoxWrapper {
    font-family: Verdana, sans-serif;
    margin: 0;
}

* {
    box-sizing: border-box;
}

.row>.column {
    padding: 0 8px;
}

.row:after {
    content: "";
    display: table;
    clear: both;
}

.column {
    float: left;
    width: 25%;
}

/* The Modal (background) */
.modal {
    position: fixed;
    z-index: 1;
    padding-top: 100px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: black;
}

/* Modal Content */
.modal-content {
    display: block !important;
    position: relative;
    background-color: #fefefe;
    margin: auto;
    padding: 0;
    width: 90%;
    max-width: 1200px;
}

/* The Close Button */
.close {
    color: white;
    position: absolute;
    top: 10px;
    right: 25px;
    font-size: 35px;
    font-weight: bold;
}

.close:hover,
.close:focus {
    color: #999;
    text-decoration: none;
    cursor: pointer;
}

.mySlides {
    display: none;
}

.cursor {
    cursor: pointer;
}

/* Next & previous buttons */
.prev,
.next {
    cursor: pointer;
    position: absolute;
    top: 50%;
    width: auto;
    padding: 16px;
    margin-top: 10rem;
    color: white;
    font-weight: bold;
    font-size: 20px;
    transition: 0.6s ease;
    border-radius: 0 3px 3px 0;
    user-select: none;
    -webkit-user-select: none;
}

/* Position the "next button" to the right */
.next {
    right: 0;
    border-radius: 3px 0 0 3px;
}

/* On hover, add a black background color with a little bit see-through */
.prev:hover,
.next:hover {
    background-color: rgba(0, 0, 0, 0.8);
}

/* Number text (1/3 etc) */
.numbertext {
    color: #f2f2f2;
    font-size: 12px;
    padding: 8px 12px;
    position: absolute;
    top: 0;
}

img {
    margin-bottom: -4px;
}

.caption-container {
    text-align: center;
    background-color: black;
    padding: 2px 16px;
    color: white;
}

.demo {
    opacity: 0.6;
}

.active,
.demo:hover {
    opacity: 1;
}

img.hover-shadow {
    transition: 0.3s;
}

.hover-shadow:hover {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
</style>