<template>
  <meta charset="utf-8">
  <meta name="viewport" content="width=device-width, initial-scale=1" />
  <meta property="og:type" content="business.business">
  <meta property="og:title" content="Roman Siegl Gmbh.">
  <meta property="og:url" content="romansiegl.at">
  <meta property="business:contact_data:street_address" content="Mühlenweg 7b">
  <meta property="business:contact_data:locality" content="Kirchdorf an der Krems">
  <meta property="business:contact_data:region" content="Oberösterreich">
  <meta property="business:contact_data:postal_code" content="4560">
  <meta property="business:contact_data:country_name" content="Austria">
  <link rel="icon" type="image/x-icon" href="src\assets\img\navbarlogo.png">
  <NavigationBar />
  <router-view></router-view>
  <CookieBanner />
  <FooterItem />
</template>

<script>
import CookieBanner from './components/CookieBanner.vue'
import FooterItem from './components/FooterItem'
import NavigationBar from './components/NavigationBar'

export default {
  name: 'App',
  components: {
    FooterItem,
    NavigationBar,
    CookieBanner
  }
}
</script>