<template>
  <main role="main" class="map-content">
    <div class="content">
      <div class="row">
        <div class="col" align="center">
          <h2>Bürozeiten</h2>
          <p>Termine nach telefonischer Vereinbarung</p>
          <p>Montag bis Freitag <time>08:00</time>-<time>12:00</time> Uhr</p>
          <h3>Email Telefon Fax</h3>
          <p><a href="tel:+43 7582 60546">Telefon: +43 7582 60546</a></p>
          <p><a href="fax:+4375826054624">Fax: +43 7582 60546 24</a></p>
          <p><a href="mailto:office@romansiegl.at">office@romansiegl.at</a></p>
          <h2>Adresse</h2>
          <address>Mühlenweg 7B 4560 Kirchdorf an der Krems</address>
        </div>
        <div class="col">
          <iframe width="425" height="350" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://www.openstreetmap.org/export/embed.html?bbox=14.106887876987459%2C47.90494723805606%2C14.107700586318972%2C47.906558268002236&amp;layer=mapnik&amp;marker=47.905752759297044%2C14.107295572757721" style="border: 1px solid black"></iframe><br/><small><a href="https://www.openstreetmap.org/?mlat=47.90575&amp;mlon=14.10730#map=19/47.90575/14.10729">Größere Karte anzeigen</a></small>
        </div>
      </div>
    </div>
    <hr class="featurette-divider"/>
  </main>
</template>

<script>
export default {
  name: 'ContactView',
  components: {
  }
}
</script>

<style scoped>
.map-content {
  margin-top: 5rem;
}

.google-map {
  width: 100%;
  height: 100%;
  border: 0;
}

.featurette-divider {
  margin-left: 5rem;
  margin-right: 5rem;
}
</style>
