<template>
     <main role="main" class="text-content">
        <h1>Datenschutzerklärung</h1>
        <p>Der Schutz Ihrer persönlichen Daten ist uns ein besonderes Anliegen. Wir verarbeiten Ihre Daten daher ausschließlich auf Grundlage der gesetzlichen Bestimmungen (DSGVO, TKG 2003). In diesen Datenschutzinformationen informieren wir Sie über die wichtigsten Aspekte der Datenverarbeitung im Rahmen unserer Website.
            Beim Besuch unserer Webseite wird Ihre IP-Adresse, Beginn und Ende der Sitzung für die Dauer dieser Sitzung erfasst. Dies ist technisch bedingt und stellt damit ein berechtigtes Interesse iSv Art 6 Abs 1 lit f DSGVO dar. Soweit im Folgenden nichts anderes geregelt wird, werden diese Daten von uns nicht weiterverarbeitet.</p>
        <h2>Kontakt mit uns</h2>
        <p>Wenn Sie per Formular auf der Website oder per E-Mail Kontakt mit uns aufnehmen, werden Ihre angegebenen Daten zwecks Bearbeitung der Anfrage und für den Fall von Anschlussfragen sechs Monate bei uns gespeichert. Diese Daten geben wir nicht ohne Ihre Einwilligung weiter.</p>

        <h2>Cookies</h2>
        <p>Unsere Website verwendet so genannte Cookies. Dabei handelt es sich um kleine Textdateien, die mit Hilfe des Browsers auf Ihrem Endgerät abgelegt werden. Sie richten keinen Schaden an.

            Wir nutzen Cookies dazu, unser Angebot nutzerfreundlich zu gestalten. Einige Cookies bleiben auf Ihrem Endgerät gespeichert, bis Sie diese löschen. Sie ermöglichen es uns, Ihren Browser beim nächsten Besuch wiederzuerkennen.

            Wenn Sie dies nicht wünschen, so können Sie Ihren Browser so einrichten, dass er Sie über das Setzen von Cookies informiert und Sie dies nur im Einzelfall erlauben.

            Bei der Deaktivierung von Cookies kann die Funktionalität unserer Website eingeschränkt sein.</p>

        <h2>Web-Analyse
        </h2>
        <p>Unsere Website verwendet Funktionen des Webanalysedienstes Google Analytics.

            Google LLC
            1600 Amphitheatre Parkway
            Mountain View, CA 94043
            USA
            Tel: +1 650 253 0000
            Fax: +1 650 253 0001
            E-Mail:support-at@google.com

            Dazu werden Cookies verwendet, die eine Analyse der Benutzung der Website durch Ihre Benutzer ermöglicht. Die dadurch erzeugten Informationen werden auf den Server des Anbieters übertragen und dort gespeichert.

            Sie können dies verhindern, indem Sie Ihren Browser so einrichten, dass keine Cookies gespeichert werden.

            Wir haben mit dem Anbieter einen entsprechenden Vertrag zur Auftragsdatenverarbeitung abgeschlossen.

            Ihre IP-Adresse wird erfasst, aber umgehend (z.B. durch Löschung der letzten 8 Bit) pseudonymisiert. Dadurch ist nur mehr eine grobe Lokalisierung möglich.

            Die Datenverarbeitung erfolgt auf Basis der gesetzlichen Bestimmungen des § 96 Abs 3 TKG sowie des Art 6 Abs 1 lit a (Einwilligung) und/oder f (berechtigtes Interesse) der DSGVO.

            Unser Anliegen im Sinne der DSGVO (berechtigtes Interesse) ist die Verbesserung unseres Angebotes und unseres Webauftritts. Da uns die Privatsphäre unserer Nutzer wichtig ist, werden die Nutzerdaten pseudonymisiert [Pseudonymisierung wird beim Rechtsgrund „berechtigtes Interesse“ empfohlen; dies muss mit dem Webanalysedienst abgeklärt werden].

            Die Nutzerdaten werden für die Dauer der Notwendigkeit und im Rahmen der gesetzlichen Rechte und Pflichten aufbewahrt.</p>

        <h2>Newsletter
        </h2>
        <p>Sie haben die Möglichkeit, über unsere Website unseren Newsletter zu abonnieren. Hierfür benötigen wir Ihre E-Mail-Adresse und ihre Erklärung, dass Sie mit dem Bezug des Newsletters einverstanden sind.

            Um Sie zielgerichtet mit Informationen zu versorgen, erheben und verarbeiten wir außerdem freiwillig gemachte Angaben zu primären Kontaktdaten wie z.B. der Adresse.

            Das Abo des Newsletters können Sie jederzeit stornieren. Senden Sie Ihre Stornierung bitte an folgende E-Mail-Adresse: office@romansiegl.at . Wir löschen anschließend umgehend Ihre Daten im Zusammenhang mit dem Newsletter-Versand. Durch diesen Widerruf wird die Rechtmäßigkeit der aufgrund der Zustimmung bis zum Widerruf erfolgten Verarbeitung nicht berührt.</p>

        <h2>Ihre Rechte
        </h2>
        <p>Ihnen stehen bezüglich Ihrer bei uns gespeicherten Daten grundsätzlich die Rechte auf Auskunft, Berichtigung, Löschung, Einschränkung, Datenübertragbarkeit, Widerruf und Widerspruch zu. Wenn Sie glauben, dass die Verarbeitung Ihrer Daten gegen das Datenschutzrecht verstößt oder Ihre datenschutzrechtlichen Ansprüche sonst in einer Weise verletzt worden sind, können Sie sich bei office@romansiegl.at oder der Datenschutzbehörde beschweren.</p>

        <h2>Sie erreichen uns unter folgenden Kontaktdaten:
            Roman Siegl GmbH</h2>
            <p>Mühlenweg 7b, 4560 Kirchdorf an der Krems</p>
            <p><a href="tel:+43 7582 60546 24">Telefon: +43 7582 60546 24</a></p>
            <p><a href="mailto:office@romansiegl.at">office@romansiegl.at</a></p>
    </main>
</template>

<style>
.text-content{
    padding-top: 5rem;
}
</style>
