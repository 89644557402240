<template>
    <footer class="container">
        <p>
        <router-link to="/datenschutz">Datenschutzerklärung</router-link>
         &middot;
        <router-link to="/contact">Kontakt</router-link>
         &middot;
        <router-link to="/impressum">Impressum</router-link>
        </p>
        <p> Copyright &copy; 2024 Roman Siegl GmbH</p>
    </footer>
</template>

<style scoped>
a {
    color: #333;
}

footer {
    margin-top: 30px;
    text-align: center;
}

</style>
