<template>
  <div class="partner-showcase">
    <h2 style="margin-bottom: 2rem;">Unsere Partner</h2>
    <div class="row">
      <div class="col-md" style="margin-bottom: 1rem;">
        <img id="partner-image" width="100%" height="150px" src="../assets/img/baumit-logo.png">
      </div>
      <div class="col-md" style="margin-bottom: 1rem;">
        <img id="partner-image" width="100%" height="150px" src="../assets/img/helopal-logo.png">
      </div>
      <div class="col-md" style="margin-bottom: 1rem;">
        <img id="partner-image" width="100%" height="150px" src="../assets/img/lorencic_logo.png">
      </div>
      <div class="col-md" style="margin-bottom: 1rem;">
        <img id="partner-image" width="100%" height="150px" src="../assets/img/Quarzolith-Logo.png">
      </div>
      </div>
      <div class="row">
      <div class="col-md" style="margin-bottom: 1rem;">
        <img id="partner-image" width="100%" height="150px" src="../assets/img/roefix-logo.png">
      </div>
      <div class="col-md" style="margin-bottom: 1rem;">
        <img id="partner-image" width="100%" height="150px" src="../assets/img/sto_logo.png">
      </div>
      <div class="col-md" style="margin-bottom: 1rem;">
        <img id="partner-image" width="100%" height="150px" src="../assets/img/IMG_0040.png">
      </div>
      <div class="col-md" style="margin-bottom: 1rem;" >
        <img id="partner-image" width="100%" height="150px" src="../assets/img/IMG_0041.png">
      </div>
    </div>

  </div>
</template>


<style>
#partner-image{
  object-fit: contain;
}

.col-md{
  text-align: center;
}


.partner-image{
  margin-bottom: 1rem;
}
</style>