<template>
      <h2 style="text-align: center; margin-bottom: 2rem;">Unsere Leistungen</h2>
      <div class="row">
        <div class="col-lg-3" align="center">
          <img src="..\assets\img\pexels-ksenia-chernaya-5691637.jpg" style="border-radius: 50%; width: 140px; height: 140px; border: 2px solid #555; margin: 1rem;"/>
          <h2>Innenputz</h2>
          <p>Innenputze gibt es für die unterschiedlichsten Anwendungsbereiche, egal ob für Wohnräume oder in Nassräumen
            als Untergrund für die Verfliesung. Perfekt abgestimmte Produkte sorgen in allen Räumen für ein natürliches
            Wohnklima.</p>
        </div>
        <div class="col-lg-3" align="center">
          <img src="..\assets\img\nice-house-7.jpg" style="border-radius: 50%; width: 140px; height: 140px; border: 2px solid #555; margin: 1rem;"/>
          <h2>Außenputz</h2>
          <p>Mineralische Beschichtungen für Wände im Außenbereich, welche das Mauerwerk schützen, Unebenheiten
            ausgleichen, die Oberfläche verschließen und als Untergrund für Anstriche und Endbeschichtungen dienen.</p>
        </div>
        <div class="col-lg-3" align="center">
          <img src="..\assets\img\Objekt.png" style="border-radius: 50%; width: 140px; height: 140px; border: 2px solid #555; margin: 1rem;"/>
          <h2>WDVS</h2>
          <p>Wärmedämmung bzw. Vollwärmeschutz ist nicht nur ein Schutz für die Gebäudehülle, sondern trägt auch zu
            einem angenehmen Wohngefühl im inneren bei. Mit einem Wärmedämmverbundsystem (WDVS) kann Wärmeverlust durch
            die Außenwand reduziert werden. Heizkosten und Energieverbrauch werden reduziert.</p>
        </div>
        <div class="col-lg-3" align="center">
          <img src="..\assets\img\estrich2.jpg" style="border-radius: 50%; width: 140px; height: 140px; border: 2px solid #555; margin: 1rem;"/>
          <h2>Estrich</h2>
          <p>Zementestriche sind die Lösung für Untergründe aller Art, im Alt- oder Neubau, schwimmend, gleitend oder im
            Verbund schnell und einfach zu verlegen. Auch für Fußbodenheizungen sind sie geeignet und zeichnen sich
            durch eine hohe Lebensdauer aus.
            Fließestriche verteilen und nivellieren sich automatisch und verdichten sich von selbst. Die
            selbstverdichtenden Eigenschaften von Fließestrich und seine geringe Porosität sind ein großer Vorteil beim
            Verlegen von Fußbodenheizungen.</p>
        </div>
      </div><!-- /.row -->
</template>
