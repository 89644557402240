<template>
  <h1 class="heading-center">Liebe Kunden, dieser Teil der Website wird gerade überarbeitet.</h1>
  <img class="center-image-fullscreen"
    src="../assets/img/pexels-pixabay-209717.jpg">
</template>

<style>
.heading-center {
  text-align: center;
  padding-top: 2rem;
}

.center-image-fullscreen {
  display: block;
  padding-left: 5rem;
  padding-right: 5rem;  
  padding-bottom: 5rem;
  margin-left: auto;
  margin-right: auto;
  height: 60%;
  width: 60%;
}
</style>
