<template>
  <div
    id="carouselExampleIndicators"
    class="carousel slide"
    data-bs-ride="carousel"
  >
    <div class="carousel-indicators">
      <button
        type="button"
        data-bs-target="#carouselExampleIndicators"
        data-bs-slide-to="0"
        class="active"
        aria-current="true"
        aria-label="Slide 1"
      ></button>
      <button
        type="button"
        data-bs-target="#carouselExampleIndicators"
        data-bs-slide-to="1"
        aria-label="Slide 2"
      ></button>
      <button
        type="button"
        data-bs-target="#carouselExampleIndicators"
        data-bs-slide-to="2"
        aria-label="Slide 3"
      ></button>
    </div>
    <div class="carousel-inner">
      <div class="carousel-item active">
        <img
          src="..\assets\img\constructionsite.jpg"
          class="d-block w-100"
          alt="..."
        />
        <div class="carousel-caption">
              <h1>Ihr Spezialist, für Außenputz, Innenputz, Estrich und WDVS aus Kirchdorf an der Krems</h1>
              <p>Mit langjähriger Erfahrung.</p>
        </div>
      </div>
      <div class="carousel-item">
        <img
          src="..\assets\img\pexels-skitterphoto-38070.jpg"
          class="d-block w-100"
          alt="..."
        />
        <div class="carousel-caption">
              <h1>Interesse geweckt?</h1>
              <p>Kontaktieren Sie uns einfach für ein Angebot</p>
              <p><router-link class="btn btn-danger" to="/contact">Kontakt</router-link></p>
        </div>
      </div>
      <div class="carousel-item">
        <img
          src="..\assets\img\pexels-jeshootscom-834892.jpg"
          class="d-block w-100"
          alt="..."
        />
        <div class="carousel-caption">
              <h1>Erfolgreich durch starke Partner</h1>
              <p>Dank unserer starken Partner können wir Ihnen beste Qualität bei den Produkten garantieren.</p>
        </div>
      </div>
    </div>
    <button
      class="carousel-control-prev"
      type="button"
      data-bs-target="#carouselExampleIndicators"
      data-bs-slide="prev"
    >
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Previous</span>
    </button>
    <button
      class="carousel-control-next"
      type="button"
      data-bs-target="#carouselExampleIndicators"
      data-bs-slide="next"
    >
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Next</span>
    </button>
  </div>
</template>

<style scoped>
/* RESPONSIVE CSS
-------------------------------------------------- */
#carouselExampleIndicators{
  padding-bottom: 3rem;
}

.carousel-item img {
  object-fit: cover;
  object-position: center;
  filter:brightness(50%);
  height: 70vh;
}

@media (min-width: 40em) {
  /* Bump up size of carousel content */
  .carousel-caption p {
    margin-bottom: 1.25rem;
    font-size: 1.25rem;
    line-height: 1.4;
  }

  .featurette-heading {
    font-size: 50px;
  }
}

@media (min-width: 62em) {
  .featurette-heading {
    margin-top: 7rem;
  }
}

.animate-bottom {
  position: relative;
  -webkit-animation-name: animatebottom;
  -webkit-animation-duration: 1s;
  animation-name: animatebottom;
  animation-duration: 1s;
}

@-webkit-keyframes animatebottom {
  from {
    bottom: -45px;
    opacity: 0;
  }
  to {
    bottom: 0px;
    opacity: 1;
  }
}
@keyframes animatebottom {
  from {
    bottom: -45px;
    opacity: 0;
  }
  to {
    bottom: 0;
    opacity: 1;
  }
}
</style>
