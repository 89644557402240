<template>
  <nav class="navbar navbar-expand-md navbar-light border-bottom" style="background-color: white;">
    <router-link to="/home" class="navbar-brand">
      <img src="..\assets\img\navbarlogo.png" id="brand" class="img-fluid" width="45" height="45"
        alt="Logo der Roman Siegl GmbH">
    </router-link>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse"
      aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarCollapse">
      <ul class="navbar-nav mr-auto">
        <li class="nav-item active">
          <router-link class="nav-link" to="/Home">Home</router-link>
        </li>
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown"
            aria-haspopup="true" aria-expanded="false">
            Leistungen
          </a>
          <div class="dropdown-menu" aria-labelledby="navbarDropdown">
            <router-link class="dropdown-item" to="/Estrich">Estrich</router-link>
            <router-link class="dropdown-item" to="/Innenputz">Innenputz</router-link>
            <router-link class="dropdown-item" to="/Außenputz">Außenputz</router-link>
            <router-link class="dropdown-item" to="/Wdvs">Wärmedämmung</router-link>
          </div>
        </li>
        <li class="nav-item">
          <router-link class="nav-link" to="/Contact">Kontakt</router-link>
        </li>
      </ul>
      <a class="navbar-brand navbar-right" href="https://www.facebook.com/RomanSieglGmbH">
        <img src="..\assets\img\f_logo_RGB-Black_58.png" class="img-fluid" width="30" height="30">
      </a>
      <a class="navbar-brand navbar-right" href="https://www.instagram.com/RomanSieglGmbH">
        <img src="..\assets\img\glyph-logo_May2016.png" class="img-fluid" width="30" height="30">
      </a>
    </div>
  </nav>
</template>

<style scoped>
.navbar-brand {
  margin-left: 1em;
}

.mr-auto {
  margin-right: auto !important;
}
</style>
