<template>
<div class="cookie-consent-banner" v-if="isOpen">
  <div class="cookie-consent-banner__inner">
    <div class="cookie-consent-banner__copy">
      <div class="cookie-consent-banner__header">Wir verwenden Cookies!</div>
      <div class="cookie-consent-banner__description">Wir verwenden Cookies um die Nutzungserfahrung zu verbessern. Näheres dazu finden Sie in unserer <router-link  to="/datenschutz">Datenschutzerklärung</router-link>.</div>
    <div class="form-check form-switch">
        <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault" checked disabled>
        <label class="form-check-label" for="flexSwitchCheckDefault">Essentielle Cookies</label>
    </div>
    <div class="form-check form-switch">
        <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault" v-model="analyticsChecked">
        <label class="form-check-label" for="flexSwitchCheckDefault">Analytics Cookies</label>
    </div>
    <div class="form-check form-switch">
        <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault" v-model="marketingChecked">
        <label class="form-check-label" for="flexSwitchCheckDefault">Marketing Cookies</label>
    </div>
    <div class="form-check form-switch">
        <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault" v-model="functionalChecked">
        <label class="form-check-label" for="flexSwitchCheckDefault">Funktionale Cookies</label>
    </div>
  </div>
    <div class="cookie-consent-banner__actions">
      <a href="#" class="cookie-consent-banner__cta" @click="acceptCookies">
        OK
      </a>
      <a href="#" class="cookie-consent-banner__cta cookie-consent-banner__cta--secondary" @click="denyCookies">
        Decline
      </a>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: 'CookieBanner',
  data () {
    return {
      isOpen: false,
      analyticsChecked: false,
      marketingChecked: false,
      functionalChecked: false
    }
  },
    created () {
      if (!this.checkConsent() === true) {
        this.isOpen = true;
      }
    },
  methods: {
    checkConsent () {
      return localStorage.getItem('ConsCheck:accepted', true);
    },
    acceptCookies () {
      this.isOpen = false
      localStorage.setItem('ConsCheck:accepted', true)
      localStorage.setItem('ConsCheck:analytics', this.analyticsChecked);
      localStorage.setItem('ConsCheck:marketing', this.marketingChecked);
      localStorage.setItem('ConsCheck:functional', this.functionalChecked);
    },
    denyCookies () {
      this.isOpen = false
      localStorage.setItem('ConsCheck:accepted', false);
      localStorage.setItem('ConsCheck:analytics', this.analyticsChecked);
      localStorage.setItem('ConsCheck:marketing', this.marketingChecked);
      localStorage.setItem('ConsCheck:functional', this.functionalChecked);
    }
  }
}
</script>

<style>
.cookie-consent-banner {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 2147483645;
  box-sizing: border-box;
  width: 100%;
  background-color: #F1F6F4;
}

.cookie-consent-banner__inner {
  max-width: 960px;
  margin: 0 auto;
  padding: 32px 0;
}

.cookie-consent-banner__copy {
  margin-bottom: 16px;
}
.cookie-consent-banner__header {
  margin-bottom: 8px;

  font-family: "CeraPRO-Bold", sans-serif, arial;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
}

.cookie-consent-banner__description {
  font-family: "CeraPRO-Regular", sans-serif, arial;
  font-weight: normal;
  color: #838F93;
  font-size: 16px;
  line-height: 24px;
}

.cookie-consent-banner__cta {
  box-sizing: border-box;
  display: inline-block;
  min-width: 164px;
  padding: 11px 13px;

  border-radius: 2px;

  background-color: #2CE080;

  color: #FFF;
  text-decoration: none;
  text-align: center;
  font-family: "CeraPRO-Regular", sans-serif, arial;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
}

.cookie-consent-banner__cta--secondary {
  padding: 9px 13px;

  border: 2px solid #3A4649;

  background-color: transparent;

  color: #2CE080;
}

.cookie-consent-banner__cta:hover {
  background-color: #20BA68;
}

.cookie-consent-banner__cta--secondary:hover {
  border-color: #838F93;

  background-color: transparent;

  color: #22C870;
}

.cookie-consent-banner__cta:last-child {
  margin-left: 16px;
}
</style>
