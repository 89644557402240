<template>
<div class="text-container">
    <h1>Impressum</h1>
    <p>Roman Siegl GmbH</p>
    <p><a href="tel:+43 7582 60546 24">F: +43 7582 60546 24</a></p>
    <p><a href="mailto:office@romansiegl.at">office@romansiegl.at</a></p>
    <p><a href="www.romansiegl.at">www.romansiegl.at</a></p>
    <p>Firmenbuchnummer: FN376746p</p>
    <p>UID-Nr. ATU67087314</p>
    <p>DGNR 901865009</p>
    <p>Mitglied der Wirtschaftskammer OÖ, Sparte Gewerbe und Handwerk, Landesinnung Bau.</p>
    <p>Sämtliches Bildmaterial darf nur mit ausdrücklicher Genehmigung der Roman Siegl GmbH weiterverwendet oder veröffentlicht werden!</p>
    <p><a href="https://firmen.wko.at/roman-siegl-gmbh--aussenputz---innenputz---estrich---vollw%C3%A4rmeschutz/ober%C3%B6sterreich/?firmaid=cfb3448a-b83c-40bc-a990-67c580b088a8">WKO-Seite</a></p>
</div>

<hr/>
</template>

<style scoped>
.text-container{
    text-align: center;
    padding-top: 1rem;
}
</style>
