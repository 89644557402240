<template>
  <main role="main">
    <CookieBanner/>
    <CarouselItem/>
    <div class="container marketing">
      <ServicesComponent/>
      <ProjectShowCase/>
      <hr class="featurette-divider" />
      <PartnerComponent/>
      <hr class="featurette-divider" />
      <ServiceTestimonial />
      <hr class="featurette-divider" />
    </div>
  </main>
</template>

<script>
import CarouselItem from '../components/CarouselItem'
import ServicesComponent from '../components/ServicesComponent'
import ProjectShowCase from '../components/ProjectShowCase'
import PartnerComponent from '../components/PartnerComponent'
import ServiceTestimonial from '../components/ServiceTestimonial'
import OpenerComponent from '../components/OpenerComponent'

export default {
  name: 'HomeView',
  components: {
    CarouselItem,
    ServicesComponent,
    PartnerComponent,
    ProjectShowCase,
    ServiceTestimonial
  }
}
</script>

<style>
 #app {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif
}

body {
  padding-top: 0rem;
  padding-bottom: 3rem;
  color: #5a5a5a;
}

.container-marketing{
    padding: 3rem;
}

/*custom button color*/
.btn-siegl {
  background-color: #e30613;
  color: white;
}

#jumbotronbg {
  background-image: url("../assets/img/constructionsite.jpg");
  background-size: cover;
}

.text-content {
  max-width: 720px;
  text-align: center;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}
/* MARKETING CONTENT
-------------------------------------------------- */

/* Center align the text within the three columns below the carousel */
.marketing .col-lg-4 {
  margin-bottom: 1.5rem;
  text-align: center;
}
.marketing h2 {
  font-weight: 400;
}
.marketing .col-lg-4 p {
  margin-right: 0.75rem;
  margin-left: 0.75rem;
}

/* Featurettes
------------------------- */

.featurette-divider {
  margin: 2rem 0; /* Space out the Bootstrap <hr> more */
}

/* Thin out the marketing headings */
.featurette-heading {
  font-weight: 300;
  line-height: 1;
  letter-spacing: -0.05rem;
}
</style>
